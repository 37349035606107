<template>
  <Verification
    request-name="PEN"
    app-name="GetMyPEN"
  ></Verification>
</template>

<script>
import Verification from '../Verification';

export default {
  name: 'studentRequestVerification',
  components: {
    Verification
  },
};
</script>
